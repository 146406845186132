import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const ProductGalleryWrapper = ({ children, contentPosition }) => {
    const [css] = useStyletron();

    return (
        <div className={css({ aspectRatio: [null, null, 'var(--ratio-vertical-primary)'] })}>
            <div
                className={css({
                    height: '100%',
                    display: 'flex',
                    justifyContent: contentPosition,
                    alignItems: 'center',
                    margin: [`0 calc(var(--margin-content) * -1)`, null, '0 -8px'],
                })}
            >
                <div className={css({ width: ['100%', null, '80%'], padding: ['0', null, '0 8px'] })}>{children}</div>
            </div>
        </div>
    );
};

ProductGalleryWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    contentPosition: PropTypes.string.isRequired,
};

export default ProductGalleryWrapper;
